
import Vue from "vue";
import API from "@/api/API";

import KothingEditor from "kothing-editor";
import kothingConfig from "../config/editorConfig.json";
import plugins from "kothing-editor/lib/plugins";
import lang from "kothing-editor/lib/lang";
import "kothing-editor/dist/css/kothing-editor.min.css";

import rules from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "Edit",

  data: () => ({
    rules,
    kothingConfig,
    item: {} as any,
    editor_en: "" as any,
    editor_ro: "" as any,
    editor_ru: "" as any,
    currentTab: 0 as number,
    userToSendEmailTo: null as number | null,
    languages: ["en", "ro", "ru"] as Array<string>,
    flags: {
      en: require("@/assets/images/locales/en.svg"),
      ro: require("@/assets/images/locales/ro.svg"),
      ru: require("@/assets/images/locales/ru.svg")
    } as any,
    subjects: {} as any,
    editorRequirements: [[], [], []] as Array<Array<any>>,
    missingKeys: [
      [] as Array<string>,
      [] as Array<string>,
      [] as Array<string>
    ] as any,
    selects: {
      users: [] as Array<any>,
      services: [] as Array<any>
    } as any,
    errorMessages: {} as { [value: string]: Array<string> },
    loading: false as boolean,
    forceKey: 0 as number
  }),

  computed: {
    services() {
      return this.selects.services;
    }
  },

  mounted() {
    this.createEditor();
    this.setEditorsContent();
    this.loadData();
  },

  methods: {
    async submit(): Promise<void> {
      this.loading = true;
      try {
        this.setNewEditorContent();
        if (this.passedValidation()) {
          await API.documentTemplates().create(this.item);
          await this.$router.push("/document-templates");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("document-templates.modals.alert.successful-edit")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    createEditor(): void {
      const language = this.$store.getters["localization/getCurrent"];
      this.editor_en = KothingEditor.create("editor_en", {
        plugins: plugins,
        lang: (lang as any)[language],
        ...this.kothingConfig
      });
      this.editor_ro = KothingEditor.create("editor_ro", {
        plugins: plugins,
        lang: (lang as any)[language],
        ...this.kothingConfig
      });
      this.editor_ru = KothingEditor.create("editor_ru", {
        plugins: plugins,
        lang: (lang as any)[language],
        ...this.kothingConfig
      });
    },
    contains(target: any, pattern: Array<string>, index: number): boolean {
      let passed = true;
      for (const item of pattern) {
        if (!target.includes(item)) {
          this.missingKeys[index].push(item);
          passed = false;
        }
      }
      return passed;
    },
    passedValidation(): boolean {
      let passed = true;
      this.missingKeys = [[], [], []];

      for (const lang of this.languages) {
        if (!this.item[`content_${lang}`] || !this.item[`name_${lang}`]) {
          this.missingKeys[this.languages.indexOf(lang)].push(1);
          passed = false;
        }
      }

      return passed;
    },
    setEditorsContent(): void {
      for (const item of this.languages) {
        this[`editor_${item}`].setContents(this.item[`content_${item}`]);
        this.subjects[`${item}`] = this.item[`name_${item}`];
      }
    },
    setNewEditorContent(): void {
      for (const item of this.languages) {
        this.item[`content_${item}`] = this[`editor_${item}`].getContents();
      }
    },
    async loadData(): Promise<void> {
      try {
        this.selects.services = await API.serviceTypes().show();

        this.forceKey++;
        this.$forceUpdate();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
